import './App.css';
import NavbarMiddleLogo from './components/NavbarMiddleLogo';
import HeroSection from './components/HeroSection';
import CompanySlide from './components/CompanySlide';
import BottomToolbar from './components/BottomToolbar';
import Pricing from './components/Pricing';
import Faq from './components/Faq';
import FinePrint from './components/FinePrint'; 
import RecentProjects from './components/RecentProjects';
import HowItWorks from './components/HowItWorks';
import About from './components/About';
import BuiltWithReact from './components/BuiltWithReact';
import RUReady from './components/RUReady';
import WhatWeDoBest from './components/WhatWeDoBest';
import ConnectExisting from './components/ConnectExisting';
import DevelopmentTimeline from './components/DevelopmentTimeline';
import BookACall from './components/BookACall';
import ProductTypes from './components/ProductTypes';

function App() {
  return (
    <div className='overflow-x-hidden relative'>
      <BuiltWithReact/>
      <NavbarMiddleLogo />
      <HeroSection />
      <CompanySlide />
      <RecentProjects/>
      <About />
      <RUReady />
      <HowItWorks />
      <WhatWeDoBest/> 
      <ProductTypes/>
      {/* <ConnectExisting /> */}
      <DevelopmentTimeline />

      <BottomToolbar />
      <Pricing />
      {/* <div className='sm:flex sm:flex-row sm:pb-20 sm:px-24'> */}
          <Faq />
      <BookACall />
      {/* </div> */}
    
      <FinePrint />
    </div>
  );
}

export default App;
