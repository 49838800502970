import React, { useState, useEffect, useRef } from 'react';


function Carousel({ items, offset }) {
  return (
    <div style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
      {items.map((item, index) => (
        <img
          key={index}
          src={item.content}
          alt={`Slide ${index}`}
          style={{
            width: isMobile() ? '240px' : '400px',  // Ensure square dimensions
            height: isMobile() ? '240px' : '400px',
            display: 'inline-block',
            marginRight: '10px',
            marginLeft: '10px',
            borderRadius: '20px',
            objectFit: 'cover',
            objectPosition: 'center',
            transform: `translateX(${offset}px)`,
            transition: 'transform 0.5s ease',
          }}
        />
      ))}
    </div>
  );
}

function isMobile() {
  return window.innerWidth <= 768; // Assuming mobile devices have a maximum width of 768 pixels
}
  
  export default function ScrollAwareCarousel() {
    const [activeIndex, setActiveIndex] = useState(0);
    const scrollContainerRef = useRef(null); // Reference to the horizontal scroll container
    const items = [
        { content: '/test.avif' },
        { content: '/test2.avif' },
        { content: '/test3.avif' },
        { content: '/test4.avif' },
        { content: '/test5.avif' },
        { content: '/test.avif' },
        { content: '/test2.avif' },
        { content: '/test3.avif' },
        { content: '/test4.avif' },
        { content: '/test5.avif' },
        { content: '/test.avif' },
        { content: '/test2.avif' },
        { content: '/test3.avif' },
        { content: '/test4.avif' },
        { content: '/test5.avif' },
        { content: '/test.avif' },
        { content: '/test2.avif' },
        { content: '/test3.avif' },
        { content: '/test4.avif' },
        { content: '/test5.avif' },
 
      
      ];
    //   const scrollRight = () => {
    //     if (scrollContainerRef.current) {
    //       scrollContainerRef.current.scrollLeft += 100; // Scrolls right by 100 pixels
    //     }
    //   }; 4500 total width
    const lastScrollY = useRef(0);  // useRef to keep track of the last scroll position
    useEffect(() => {
        if (scrollContainerRef.current) {
        
          scrollContainerRef.current.scrollLeft = 200;
        }
      }, []); // Empty dependency array ensures this effect runs only once on mount
    
      useEffect(() => {
    
        const handleScroll = () => {
            // current view height: 2429
            const currentScrollY = window.scrollY;
            if (currentScrollY > lastScrollY.current) {
              console.log('Scrolling Down');
              if (scrollContainerRef.current) {
                scrollContainerRef.current.scrollLeft += isMobile() ? 5 : 10; // Scrolls right by 5 pixels on mobile, 10 pixels on other devices
              }
            } else if (currentScrollY < lastScrollY.current) {
              console.log('Scrolling Up');
              if (scrollContainerRef.current) {
                scrollContainerRef.current.scrollLeft -= isMobile() ? 5 : 10; // Scrolls left by 5 pixels on mobile, 10 pixels on other devices
              }
            }

            function isMobile() {
              // Use a suitable method to detect if the user is on a mobile device
              // For example, you can check the user agent string or screen width
              // Return true if it's a mobile device, false otherwise
              // Example implementation:
              return window.innerWidth <= 768; // Assuming mobile devices have a maximum width of 768 pixels
            }
          // window.scrollY gives the current vertical scroll position
          console.log(window.scrollY);
     
          lastScrollY.current = currentScrollY; 
        };
        window.addEventListener('scroll', handleScroll);
    // Cleanup function to remove the event listener
    return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []); // Empty dependency array ensures the effect runs only once on mount
  
  
    return (
      <div ref={scrollContainerRef}  className="no-scrollbar mt-4 sm:mt-24" style={{ overflowX: 'auto', whiteSpace: 'nowrap', width: '100%' }}>
        <div style={{ display: 'flex', width: `${items.length * 260}px` }}>
          <Carousel items={items} activeIndex={activeIndex} />
        </div>
      </div>
    );
  }