import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'



export default function HeroSection() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div className="bg-white">
 

      <div className="relative isolate px-6   lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div className="mx-auto max-w-md md:max-w-6xl   pt-20 sm:pt-16 md:pt-24  ">
          {/* <div className="hidden sm:mb-8 sm:flex sm:justify-center">
            <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
              Announcing our next round of funding.{' '}
              <a href="#" className="font-semibold text-indigo-600">
                <span className="absolute inset-0" aria-hidden="true" />
                Read more <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div> */}
          <div className="text-center">
            {/* <h1 className="text-5xl sm:text-6xl md:text-7xl lg:text-8xl	font-bold tracking-tight text-gray-900 ">
             The Future of Software Development
            </h1> */}
            {/* <h1 className="text-5xl sm:text-6xl md:text-7xl lg:text-8xl	font-bold tracking-tight text-gray-900  ">
             A new kind of development studio
            </h1> */}
            <h1 className="text-5xl sm:text-6xl md:text-7xl lg:text-8xl	font-bold tracking-tight text-gray-900  ">
             A development studio with a twist
            </h1>
             {/* <h1 className="text-8xl	font-bold tracking-tight text-gray-900  ">
             Higher Quality, Faster Delivery, Lower Cost Software Development 
            </h1> */}
            {/* <p className="mt-6 text-xl max-w-2xl mx-auto leading-8 text-gray-600">
            Unlimited development requests with a simple, all-inclusive monthly subscription.        </p> */}
            <p className="mt-6 text-xl max-w-2xl mx-auto leading-8 text-gray-600 ">
            Unlimited development requests. Pause or cancel anytime. </p>
            {/* Simply provide us your designs, create unlimited development requests, and let us bring your ideas to life.      */}
            {/* <p className="mt-6 text-xl leading-8 text-gray-600">
              Software Development for everyone. Pause or cancel anytime.
            </p> */}
            <div className="mt-4 flex items-center justify-center gap-x-6">
              <a
                href="#pricing"
                className="rounded-lg bg-black px-12 py-5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                See plans
              </a>
            
            </div>
            <div href="#"  className="text-sm font-semibold leading-6 text-gray-900 pt-5  ">
            <span aria-hidden="true">    <div className="pulsating-dot"></div></span>  Available Now 
              </div>
          </div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </div>
    </div>
  )
}
