import { BellIcon, CheckBadgeIcon, ShareIcon } from '@heroicons/react/20/solid'
import { useState } from 'react'
 
export default function DevelopmentTimeline() {
    const [activeNavi, setActiveNavi] = useState("Discovery")

    // Function to determine button background color
    const getButtonStyle = (buttonName) => {
        return activeNavi === buttonName ? "bg-slate-200 rounded-xl " : "bg-transparent";
    };
    const getTextStyle = (buttonName) => {
        return activeNavi === buttonName ? "text-black" : "text-gray-500";
    };
    const getDetails = () => {
        switch (activeNavi) {
            case "Discovery":
                return { Icon: BellIcon, title: "Stage #1 - Discovery", description: "The path to outstanding products is marked by a well-structured process. At SoftwareNow we set our scope early, aligning the vision, ideas, and timeframes. For businesses we take a deep dive into your existing workflows, processes, tools, and goals to spot areas ready for improvement. Based on this research we propose customized solutions that address your precise needs and specifications." };
            case "Design":
                return { Icon: CheckBadgeIcon, title: "Stage #2 - Design", description: "In the design stage, our focus shifts to translating the insights and strategies from the discovery phase into actionable design concepts. This involves creating wireframes, prototypes, and high-fidelity designs that effectively communicate the vision and function of the product." };
            case "Development":
                return { Icon: ShareIcon, title: "Stage #3 - Development", description: "During development, the rubber meets the road. Our engineers and developers work to turn the designs into a functioning product. This stage involves rigorous testing, iteration, and integration of feedback to ensure that the final product meets the high standards expected by our clients." };
            default:
                return { Icon: BellIcon, title: "Stage #1 - Discovery", description: "Default description if something goes wrong." };
        }
    };
    const { Icon, title, description } = getDetails();

    return (
        <div className="">
            <p className="text-5xl font-bold text-center pt-20">Development Timeline</p>
            <p className="max-w-md sm:mx-w-xl md:max-w-2xl mx-auto pt-8 pb-4 text-center">Building great stuff isn't just going through the motions—it's about sticking to a brilliant game plan. Explore our stages of product development.</p>

            <div className="max-w-xs sm:max-w-xl mx-auto my-8 py-4 border rounded-3xl border-2 border-black flex flex-row justify-center align-center self-center gap-x-4">
                <button className={`py-2 px-4 flex flex-row transition-colors duration-300 ease-in-out ${getButtonStyle("Discovery")}`} onClick={() => setActiveNavi("Discovery")}>
                    <BellIcon className={`h-5 w-5 text-black self-center mr-2 ${getTextStyle("Discovery")}`} aria-hidden="true" />
                    <p className={`font-semibold ${getTextStyle("Discovery")} hidden sm:flex`}>#1 Discovery</p>
                </button>
                <button className={`py-2 px-4 flex flex-row transition-colors duration-300 ease-in-out ${getButtonStyle("Design")}`} onClick={() => setActiveNavi("Design")}>
                    <CheckBadgeIcon className={`h-5 w-5 text-black self-center mr-2 ${getTextStyle("Design")}`} aria-hidden="true" />
                    <p className={`font-semibold ${getTextStyle("Design")} hidden sm:flex`}>#2 Design</p>
                </button>
                <button className={`py-2 px-4 flex flex-row transition-colors duration-200 ease-in-out ${getButtonStyle("Development")}`} onClick={() => setActiveNavi("Development")}>
                    <ShareIcon className={`h-5 w-5 text-black self-center mr-2 ${getTextStyle("Development")}`} aria-hidden="true" />
                    <p className={`font-semibold ${getTextStyle("Development")} hidden sm:flex`}>#3 Development</p>
                </button>
            </div>

            <div id='4' className='mx-10   rounded-2xl p-8 max-w-lg sm:max-w-xl md:max-w-3xl mx-auto text-center '>
                <Icon className='h-12 text-black self-center mx-auto ' aria-hidden="true" />
                <p className='text-5xl font-bold py-8'>{title}</p>
                <p>{description}</p>
            </div>
        </div>
    )
}