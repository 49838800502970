export default function BookACall() {
    return (
        <>
          <div className=' mx-auto  bg-indigo-500  text-center   rounded-xl  max-w-md py-10 my-14   h-full'>
            <h1 className="text-white text-center text-3xl font-bold pb-10 ">Book a 15-minute intro call</h1>
            <button className="bg-black rounded-lg text-white px-8 py-4 text-xl font-bold hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={() => window.open('https://calendly.com/brian-2ubb/15min', '_blank')}>
                Schedule now
            </button>
        </div>
      
        </>
    )
}   