// import { useEffect, useRef } from 'react';

// function useScrollSync() {
//   const leftColumnRef = useRef(null);
//   const rightColumnRef = useRef(null);

//   useEffect(() => {
//     const handleScroll = () => {
//       const leftColumn = leftColumnRef.current;
//       const rightColumn = rightColumnRef.current;
//             console.log(leftColumn)
//             console.log(rightColumn)

//       if (!leftColumn || !rightColumn) return;

//       const windowHeight = window.innerHeight;
//       const leftColumnHeight = leftColumn.offsetHeight ; 
//       const rightColumnHeight = rightColumn.offsetHeight ;
//       const scrollY = window.scrollY;
//       console.log(windowHeight)
//       console.log(leftColumnHeight)
//       console.log(rightColumnHeight)
//       console.log(scrollY)

//       if (scrollY > leftColumnHeight - windowHeight) {
//         leftColumn.style.position = 'sticky';
//         leftColumn.style.top = `${windowHeight - leftColumnHeight}px`;
//       } else {
//         leftColumn.style.position = 'sticky';
//         leftColumn.style.top = '0';
//       }

//       if (scrollY + windowHeight > rightColumnHeight) {
//         rightColumn.style.height = `${rightColumnHeight}px`;
//       } else {
//         rightColumn.style.height = '100vh';
//       }
//     };

//     window.addEventListener('scroll', handleScroll);
//     return () => window.removeEventListener('scroll', handleScroll);
//   }, []);

//   return { leftColumnRef, rightColumnRef };
// }



import { useEffect, useRef, useState } from 'react';

function useScrollSync() {
  const containerRef = useRef(null);
  const leftColumnRef = useRef(null);
  const rightColumnRef = useRef(null);
  const [isRightColumnScrolled, setIsRightColumnScrolled] = useState(false);

  useEffect(() => {
    const container = containerRef.current;
    const leftColumn = leftColumnRef.current;
    const rightColumn = rightColumnRef.current;
    
    if (!container || !leftColumn || !rightColumn) return;

    const handleScroll = () => {
      const containerHeight = container.offsetHeight;
      const leftColumnHeight = leftColumn.offsetHeight;
      const rightColumnHeight = rightColumn.offsetHeight;
      const scrollY = container.scrollTop;

      if (scrollY > leftColumnHeight - containerHeight) {
        leftColumn.style.position = 'sticky';
        leftColumn.style.top = `${containerHeight - leftColumnHeight}px`;
      } else {
        leftColumn.style.position = 'sticky';
        leftColumn.style.top = '0';
      }

      if (scrollY >= rightColumnHeight - containerHeight) {
        setIsRightColumnScrolled(true);
      } else {
        setIsRightColumnScrolled(false);
        rightColumn.scrollTop = scrollY;
      }
    };

    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, []);

  return { containerRef, leftColumnRef, rightColumnRef, isRightColumnScrolled };
}




// convert this to a list of heading and descriptions:
const items = [
   {title: 'SAAS Development', description:'Develop your very own Software Business! We convert your wireframe designs into robust and user-friendly applications using Bubble.io that align with your project requirements. Simply share your idea and project details then watch our developers bring your idea to life.'},
   {title:  "Custom Business Solutions", description:"Revolutionize your business by building custom solutions or seamlessly integrating all your internal tools into a tailored, single-point dashboard. Develop your own systems such as CRMs and Business Management Tools, tailored specifically to your business. You can even supercharge this with our Artificial Intelligence solutions." }, 
   {title: 'Custom AI Solutions & Automations', description:'Develop custom AI and Automation solutions tailored to your business. Harness the power of Artificial Intelligence to streamline daily tasks and transform your operations from Sales, Lead Generation, Customer Support, and beyond. Our AI solutions easily integrate into the applications your business uses daily, ensuring flexibility and compatibility with your existing systems. Paving the way for smarter, more productive workflows.'},
   {title:  "No Long Term Commitments", description:"Choose the ease of a monthly subscription, allowing you to pause your plan when jobs are scarce and pick it up whenever the workload returns. There are zero contracts or longterm commitments typically required by traditional agencies." }, 
];


export default function WhatWeDoBest() {
  // const { leftColumnRef, rightColumnRef } = useScrollSync();
  const { containerRef, leftColumnRef, rightColumnRef, isRightColumnScrolled } = useScrollSync();

    return (
        // <div className=" max-w-5xl mx-auto">

        // <div className="sm:flex sm:flex-row  ">
        //     <div className="sm:w-1/2 ">
        //         <div className="text-5xl font-bold py-10 mx-auto">
        //                 <h1>What We Do Best</h1>
        //             </div>
        //             <div className="max-w-md text-md sm:pb-40">
        //                 <p>We didn't just reinvent software development - we made it accessible, efficient, and affordable. The era of traditional coding is out.</p>
        //                 <br></br>
        //                 <p>Experience the accelerated pace of No Code development, achieving up to 5x faster project completion, with your first task completed within 72 hours.</p>
        //             </div>
        //     </div>
        //     <div className="sm:w-1/2 ">
        //     {items.map((item, index) => (
        //         <div style={{boxShadow: '8px 8px 0px 0px rgba(0,0,0,1)'}} className=' border border-4 border-black rounded-2xl my-12 p-8  '> 
        //         <div className="rounded-lg p-2" style={{ background: 'black', display: 'inline-block' }}> 
        //              <p  className={`text-white font-bold text-xl`}>{item.title}</p>
        //         </div>

        //                 <p  className="text-lg py-10">{item.description}</p>
        //         </div>
        //             ))}
        //     </div>
        // </div>
        <div id="about" className=" relative scroll-container" ref={containerRef}>
  <div className="lg:flex    mx-auto  max-w-5xl xl:max-w-6xl p-4 md:p-8 lg:p-12 ">
    <div className="lg:w-1/2 lg:sticky-column" ref={leftColumnRef}>
      <div className="sticky top-0 pt-10">
        <div className="text-5xl font-bold mb-10 text-center lg:text-left">
          <h1>What We Do Best</h1>
        </div>
        <div className="max-w-md text-md text-center lg:text-left mx-auto lg:mx-0">
          <p>We didn't just reinvent software development - we made it accessible, efficient, and affordable. The era of traditional coding is out.</p>
          <br />
          <p>Experience the accelerated pace of No Code development, achieving up to 5x faster project completion, with your first task completed within 72 hours.</p>
        </div>
      </div>
    </div>
    <div className="lg:w-1/2 scrollable-column  " ref={rightColumnRef}>
    <div className={`scrollable-content ${isRightColumnScrolled ? 'pb-[100vh]' : ''}`}>


      {items.map((item, index) => (
        <div
          key={index}
          style={{boxShadow: '8px 8px 0px 0px rgba(0,0,0,1)'}}
          className='border border-4 border-black rounded-2xl my-12 p-8'
        >
          <div className="rounded-lg p-2 inline-block bg-black">
            <p className="text-white font-bold text-xl">{item.title}</p>
          </div>
          <p className="text-lg py-10">{item.description}</p>
        </div>
      ))}
       
        </div>

    </div>
  </div>




        </div>
    )
}