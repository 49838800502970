export default function Example() {
    const scrollStyle = {
        animation: `swipe ${15000}ms linear infinite`,
      };
    const compImages = [
        {src:'./companylogos/fiserv.png'},
        {src:'./companylogos/pw.png'},
        {src:'./companylogos/macys.png'},
        {src:'./companylogos/googlew.png'},
        {src:'./companylogos/fiserv.png'},
        {src:'./companylogos/pw.png'},
        {src:'./companylogos/macys.png'},
        {src:'./companylogos/googlew.png'},
        {src:'./companylogos/fiserv.png'},
        {src:'./companylogos/pw.png'},
        {src:'./companylogos/macys.png'},        
        {src:'./companylogos/googlew.png'},
        {src:'./companylogos/fiserv.png'},
        {src:'./companylogos/pw.png'},
        {src:'./companylogos/macys.png'},
        {src:'./companylogos/googlew.png'},

        {src:'./companylogos/fiserv.png'},
        {src:'./companylogos/pw.png'},
        {src:'./companylogos/macys.png'},
        {src:'./companylogos/googlew.png'},

        {src:'./companylogos/fiserv.png'},
        {src:'./companylogos/pw.png'},
        {src:'./companylogos/macys.png'},
        {src:'./companylogos/googlew.png'},

        {src:'./companylogos/fiserv.png'},
        {src:'./companylogos/pw.png'},
        {src:'./companylogos/macys.png'},
        {src:'./companylogos/googlew.png'},

        {src:'./companylogos/fiserv.png'},
        {src:'./companylogos/pw.png'},
        {src:'./companylogos/macys.png'},
        {src:'./companylogos/fiserv.png'},
        {src:'./companylogos/googlew.png'},

        {src:'./companylogos/pw.png'},
        {src:'./companylogos/macys.png'},
        {src:'./companylogos/fiserv.png'},
        {src:'./companylogos/pw.png'},
        {src:'./companylogos/macys.png'},
        {src:'./companylogos/fiserv.png'},
        {src:'./companylogos/pw.png'},
        {src:'./companylogos/macys.png'},
        {src:'./companylogos/fiserv.png'},
        {src:'./companylogos/googlew.png'},

        {src:'./companylogos/pw.png'},
        {src:'./companylogos/macys.png'},
    ]
    return (
      <>
       <div className=" flex justify-center pt-10 ">
          <p className="relative rounded-full bg-gray-50 px-4 py-1.5 text-sm leading-6 text-gray-600 ring-1 ring-inset ring-gray-900/5">
            <span className="hidden md:inline">Top companies use our services to better their business.</span>
            <a href="#" className="font-semibold text-black">
              <span className="absolute inset-0" aria-hidden="true" /> Read our customer stories{' '}
              <span aria-hidden="true">&rarr;</span>
            </a>
          </p>
        </div>
      <div className="bg-white py-6 banner-wrapper pt-10 " >
          {/* <h2 className="text-center text-lg font-semibold leading-8 text-gray-900">
            Hired By
          </h2> */}
          
        <div className=" max-w-md md:max-w-3xl lg:max-w-5xl px-6 lg:px-8 wrapper bg-black rounded-3xl py-4 overflow-hidden mx-auto ">
        
          <div style={scrollStyle} className="mx-auto  flex-row flex items-center gap-x-8 gap-y-10    sm:gap-x-10   ">
           {compImages.map((img) =>(

<img
className="col-span-2 max-h-12 w-full object-contain lg:col-span-1  "
src={img.src}
alt="Transistor"
width={158}
height={48}
/>
          ) )}
        
         

          </div>
           
          
        </div>
      </div></>
    )
  }
  