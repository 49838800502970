import { InboxIcon, TrashIcon, UsersIcon } from '@heroicons/react/24/outline'
import SlidingImages from './slidingImages'
export default function About() {
    const features = [
        {
          title: 'Reduce Costs',
          paragraph:
            'Minimize unnecessary costs hiring expensive or unqualified developers. Let us focus on high ROI development tasks while you focus on your business.',
          href: '#',
          icon: '/check.png',
        },
        {
          title: 'Increase Speed',
          paragraph:
            'Eliminate slowdowns with speedier project cycles and fewer meetings, accelerating your development process by up to 4x.',
          href: '#',
          icon: '/check.png',
        },
        {
          title: 'Quality Control',
          paragraph:
            'Ensure high quality development by minimizing miscommunication and inconsistencies through project management.',
          href: '#',
          icon: '/check.png',
        },
    
      ]
      
    return (
        <div className=' sm:outline rounded-xl mx-8 py-10 sm:py-32 my-14 sm:my-20 '>
        <div  className=" text-center max-w-4xl mx-auto  ">
            <h1 className="text-5xl font-bold tracking-tight text-gray-900 sm:text-6xl max-w-3xl mx-auto">
            Redefining Software Development

            </h1>
            {/* <h1 className="text-5xl font-bold tracking-tight text-gray-900 sm:text-7xl">
            We didn’t reinvent the wheel. Just development.

            </h1> */}
            <p className="mt-6 text-xl leading-8 text-gray-600 py-6 max-w-3xl mx-auto">
            Skip the hassle of vetting developers or dealing with outsourcing - we replace conventional developers and costly agencies with a simple monthly payment. The perfect solution for startups and businesses.            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600 font-bold">
            ‍Welcome to the future of AI, Software, and Automation Development.
            </p>
        
            
        </div>
        <div className="mx-auto mt-16 max-w-4xl sm:mt-20 lg:mt-24   text-center  ">
          <dl className="grid   grid-cols-1 gap-x-8 gap-y-16   lg:grid-cols-3 ">
            {features.map((feature) => (
              <div key={feature.title} className="flex flex-col outline rounded-xl p-4 bg-[#f2f0ea]">
                {/* <div className='my-auto'> */}
            <img src={feature.icon} alt='check' className="w-10 mx-auto"></img>
                <p className="text-2xl font-semibold leading-7 text-gray-900 py-6 ">
                  {feature.title}
                </p>
                <p className="text-lg leading-7 text-gray-900 ">
                  {feature.paragraph}
                </p>
</div>
            //   </div>
            ))}
            
          </dl>
          <div className='py-20'>
                      <SlidingImages/>

          </div>

        </div>
        </div>
    );
}
 