import Timeline from './timeline';
  
export default function HowItWorks() {
const items = [{
    img: "/contact.png",
    heading: "Book a Discovery Call",
    sub: "Lets chat about your business...",
    para: "Schedule a Discovery Call with us to understand your business, project idea, and clarify your vision. We'll examine your current systems and create actionable next steps while answering any questions you might have about our service.",
  },
  {
    img: "/cal.png",
    heading: "Subscribe & Add Your Project Details",
    sub: "Time to set up your Project Management Board...",
    para: "Once subscribed add your project details to your Trello board. This includes uploading designs, outlining functionality, and providing all information that will guide the development process. Don't worry we'll guide you in setting up your project board correctly.",
  },
  {
    img: "/create.png",
    heading: "Create Your Project Tasks",
    sub: "We create your tasks and set up Weekly Sprints",
    para: "We breakdown your project details into actionable development tasks. Your developer will then create a weekly 'Sprint’ cycle comprised of multiple tasks that will be due end of week. Each task throughout the week will be marked for review once completed.",
  },
  {
    img: "/revisions.png",
    heading: "Request Unlimited Revisions",
    sub: "Once a task is reviewed mark it as complete...",
    para: "We revise each tasks until you’re 100% happy. Once you approve a task, we move onto the next and each week a new sprint will be created consisting of new tasks. If a task from a previous sprint is still 'in-progress' that task will roll over into the new sprint.",
},
  {
    img: "/tracks.png",
    heading: "Track and Manage your Progress",
    sub: "Watch as we bring your idea to life...",
    para: "Simply login and track your projects progress using Trello. We make it easy to review tasks, assign revisions, add comments, view links, and share new details with your developer. We understand that development needs can fluctuate. That's why you can pause your subscription if you have no tasks and resume when you do.",
}]
  return (
       <div id='how' className=' rounded-xl mx-auto px-8 py-10 sm:pt-20 my-14 sm:my-20 max-w-5xl xl:max-w-6xl'>
         
       <p className='text-5xl font-bold text-center sm:text-6xl'>How it Works</p>
       <p className="max-w-md sm:max-w-2xl mx-auto py-10">Say goodbye to the lengthy onboarding processes associated with agencies. Simply subscribe, add your project details, then watch us set up and deliver your first task within 72 hours.</p>
       <div className='max-w-3xl mx-auto '>
       {items.map((item, index) => (
        <Timeline key={index} sub={item.sub} heading={item.heading} para={item.para} img={item.img}/>
      ))}
       </div>
       
       </div>
    );
}
 