import { BoltIcon, CheckIcon, LightBulbIcon } from '@heroicons/react/20/solid'
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
const includedFeatures = [
'One request at a time',
'Average 48 hour delivery', 
 
// 'Development for all frameworks and languages', 
// '3 day sprints',
'Pause or cancel anytime']
const proIncludedFeatures = [
'Two requests at a time',

'Average 48 hour delivery', 
 
'Development for all frameworks and languages', 

'Pause or cancel anytime']



export default function Example() {
    const [standard, setStandard] = useState(true)
  return (
    <div className="bg-white py-24 sm:py-32 ">
      <div id='pricing'  className="mx-auto max-w-7xl px-6 lg:px-8">
     
        <div className="mx-auto max-w-2xl rounded-3xl border border-2 border-black lg:mx-0 lg:flex lg:max-w-none">
          <div className=" px-4 md:px-10 py-10 lg:flex-auto lg:w-1/2 ">
            <div className='bg-rose-400 rounded-3xl px-4 py-3 mb-6 max-w-40 text-center'>
                <span>   <div className="pulsating-dot-black"></div></span>Slots available
            </div>
            <h3 className="text-5xl lg:text-6xl font-bold tracking-tight text-gray-900">SoftwareNow</h3>
            <p className="mt-6 text-base leading-7 text-gray-600">
            One subscription, unlimited possibilities. Our pricing model is as clear and straightforward as it can get.
<br/>
<br />
Pay a simple, flat monthly subscription fee and unlock unlimited access to our No Code software development solutions. No hidden charges, no additional fees. You are in full control of canceling or pausing your subscription at anytime.
<br />
<br />
Enjoy unlimited requests, revisions, and tasks. This means we won't stop working until you're 100% satisfied with our software solutions.
            </p>
                <button
        type="button"
        className="text-left mt-10  w-7/12  gap-x-1.5 rounded-md bg-black/90 px-3 py-2 text-2xl font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => window.open('https://calendly.com/brian-2ubb/15min', '_blank')}
      >
        Book a 15-min intro call
        <ArrowRightIcon className="-mr-0.5 h-5 w-5 fill-red-600" aria-hidden="true" />
        
        <p className='text-lg w-10/12'>Learn more about how SoftwareNow  works and how it can help you.</p>
      </button>
           
          </div>
          <div className="-mt-2 p-2 lg:mt-0   lg:flex-shrink-0 lg:w-1/2">
            <div className="rounded-2xl bg-gray-50 py-10  ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="max-w-lg px-4 md:px-8">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl pb-6">
                Membership</h2>

                <div className=' w-4/6 lg:w-3/4 bg-black rounded-3xl justify-between flex  '>
                    <button onClick={()=>setStandard(true)} className={standard ? 'text-black py-2  px-8 bg-yellow-400  rounded-3xl ' : 'text-white py-2  px-8 ' }>Standard</button>
                    <button onClick={()=>setStandard(false)} className={standard ? 'text-white py-2  px-12  inline-flex items-center gap-x-1.5 ' : 'inline-flex items-center gap-x-1.5  text-black py-2  px-12 bg-yellow-400  rounded-3xl ' }>
                        <span className='  h-5 w-5  '  ><BoltIcon className='fill-red-600'></BoltIcon></span>
                        Pro</button>
                </div>

                {/* <p className="text-base font-semibold text-gray-600">Pay once, own it forever</p> */}
                <p className="mt-6 flex items-baseline  gap-x-2 pt-4">
                  <span className="text-5xl font-bold tracking-tight text-gray-900">{standard? '$4,995' : '$9,495'}</span>
                  <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">/m</span>
                </p>
                <p className="text-base font-semibold text-gray-600 pt-2">{standard? "One request at a time. Pause or cancel anytime." : "Two requests at a time. Pause or cancel anytime"}</p>


                <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-black">What’s included</h4>
              <div className="h-px flex-auto bg-gray-100 md:bg-gray-300" />
            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
              {standard ? includedFeatures.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-black" aria-hidden="true" />
                  {feature}
                </li>
              )) :
              proIncludedFeatures.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-black" aria-hidden="true" />
                  {feature}
                </li>
              ))
              }
            </ul>

<div className='inline-flex items-center gap-x-6'>


                <a
                  href="#"
                
                  className="mt-10 block  max-w-48 rounded-xl bg-black/90 px-12 py-6 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Get started
                </a>

                <p className="mt-6 text-xs leading-5 text-gray-600 ">
                  or <span className="font-semibold text-gray-900"> 
                    <a  href='https://calendly.com/brian-2ubb/15min' cursor='pointer'>book a call</a>
                    </span>
                </p>
</div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
