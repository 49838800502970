import React from 'react';
import './styles/BottomToolbar.css'; // Make sure to import the CSS

const BottomToolbar = () => {
  return (
 
    <div className='fixed bottom-10 w-full  invisible md:visible '>
    <div className="text-center mx-auto max-w-2xl  
    overflow-hidden bg-black rounded-3xl h-14 flex justify-center items-center gap-x-4
     ">
      <a href='#how'className="text-white text-lg hover:bg-white/20   hover:rounded-3xl    px-6 ">How it works</a>
      <a href='#about'className="text-white text-lg hover:bg-white/20   hover:rounded-3xl    px-6 ">About</a>
      <a href='#services'className="text-white text-lg hover:bg-white/20   hover:rounded-3xl    px-6 ">Services</a>
      <a href='#pricing' className="text-white text-lg hover:bg-white/20   hover:rounded-3xl    px-6 ">Pricing</a>
      <a href='#faq' className="text-white text-lg hover:bg-white/20   hover:rounded-3xl    px-6 ">FAQ</a>
      {/* <button className="text-white text-lg hover:bg-white/20   hover:rounded-3xl  py-4 px-6 ">Login</button> */}
    </div>
    </div>
    
  );
}

export default BottomToolbar;
