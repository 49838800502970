import React from 'react';
const listItems = [
    "Rapid MVP Prototyping",
    "From Ideas to Production Ready Software",
    "Accelerated Development Timelines"
];
const listItems2 = [
    "Custom Dashboards, CRMs, Internal Tools",
    "AI Trained Chatbots (Support & Lead Gen)",
    "Smooth Integration with all your platforms"
];
export default function RUReady() {
    return (
      
    <div className='justify-center sm:flex sm:flex-row gap-x-8 px-10 max-w-7xl mx-auto'>
        <div className='sm:w-1/2 outline rounded-md p-12'>
        <p style={{ boxShadow: "-10px 10px 0px 0px rgba(33,33,33,1), -20px 20px 0px 0px rgba(33,33,33,0.7), -30px 30px 0px 0px rgba(33,33,33,0.4), -40px 40px 0px 0px rgba(33,33,33,0.1)" }} className='p-1 border border-black border-2 rounded-xl drop-shadow-xl w-32 mb-10'>FOR STARTUPS</p>
        <p className='text-3xl font-semibold pb-4'> Ready to Build your own Software?</p>
        <p className='text-lg'>The conventional coding process is slow, bulky, and can be a drain on resources. SoftwareNow taps into the capabilities of no-code development, enabling us to develop MVPs for a fraction of the time and cost.</p>
        {listItems.map((item) => (
            <div className='flex flex-row'>
                <img src='/a.png' alt='a' className='h-10'></img>
                <p className='self-center'>{item}</p>
            </div>
        ))}
        {/* <img src='/computer.png' alt='c'  className='h-72 rounded-2xl mx-auto mt-10'></img> */}
        <video src='/code1.mp4' alt='c' playsInline autoPlay muted loop  className='h-72 rounded-2xl mx-auto mt-10'></video>
        </div>
        <div className='sm:w-1/2 outline rounded-md p-12 mt-10 sm:mt-0'>
        <p style={{ boxShadow: "-10px 10px 0px 0px rgba(33,33,33,1), -20px 20px 0px 0px rgba(33,33,33,0.7), -30px 30px 0px 0px rgba(33,33,33,0.4), -40px 40px 0px 0px rgba(33,33,33,0.1)" }} className='p-1 border border-black border-2 rounded-xl drop-shadow-xl w-36 mb-10'>FOR BUSINESSES</p>

        <p className='text-3xl font-semibold pb-4'>Build Custom Solutions for your Business</p>
        <p className='text-lg'>Discover a new level of streamlined processes like you've never experienced before. Using AI and No Code to reshape your business from custom tools, task completion, automation, and so much more.</p>
        {listItems2.map((item) => (
            <div className='flex flex-row'>
                <img src='/a.png' alt='a' className='h-10'></img>
                <p className='self-center'>{item}</p>
            </div>
        ))}
                <video src='/business.mp4' alt='c' playsInline  autoPlay muted loop className='h-72 rounded-2xl mx-auto mt-10'></video>

        </div>
    </div> 
    )
    }
 