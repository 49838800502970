import React, { useState } from 'react';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';

const ImageOverlay = () => {
  const [sliderPosition, setSliderPosition] = useState(50);

  const handleSliderChange = (event) => {
    setSliderPosition(event.target.value);
  };

  return (
 
    <ReactCompareSlider
    itemOne={<ReactCompareSliderImage src='/wireframe.png'   alt="Image one" />}
    itemTwo={<ReactCompareSliderImage src='/product.png' alt="Image two" />}
  />
   );
};

export default ImageOverlay;
