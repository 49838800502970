// "Built with React" tag/banner on the top right corner of the page, positioned absolute.
import React from 'react';

export default function BuiltWithReact() {
    return (
        <div className="absolute top-12 -right-[340px] z-10 " style={{ transform: 'rotate(45deg)' }}>
            <div className="bg-black text-white text-sm font-semibold   py-2 justify-center rounded-tl-lg rounded-bl-lg flex-row flex w-[800px]">
                <p>Built with React</p>
                <img src={'/react.png'} className='h-4 w-4 mx-2' alt="React Logo" />
            </div>
        </div>
    )
}



