
const logos = [
    {
      src: "/memphis-gear-wheel.png",
      alt: "saas",
      title: "SAAS Development",
      description: "Develop your software application MVP faster than ever. Speed is your friend.",
    },
    {
      src: "/memphis-robot.png",
      alt: "saas",
      title: "Artificial Intelligence",
      description: "Develop Simple to Advanced AI Applications using Large Language Models.",
    },
    {
      src: "/memphis-tablet-for-papers.png",
      alt: "saas",
      title: "Business Management",
      description: "Develop your own custom internal business management application.",
    },
    {
      src: "/crm.png",
      alt: "saas",
      title: "Custom CRM Systems",
      description: "Develop CRM systems for your business tailored specifically to your clients. No More 3rd Party Apps.",
    },
    {
      src: "/speech.png",
      alt: "saas",
      title: "Custom AI Chatbots",
      description: "Build custom knowledge based AI chatbot systems that can be applied with a variety of use cases.",
    },
    {
      src: "/memphis-smartphone.png",
      alt: "UIUX",
      title: "App Design (UI/UX)",
      description: "Design beautiful UI/UX wireframes for your software application and branding assets.",
    },
    {
      src: "/website.png",
      alt: "saas",
      title: "Custom Websites",
      description: "Create a unique and visually appealing website tailored to your brand.",
    },
    {
      src: "/automation.png",
      alt: "saas",
      title: "Workflow Automation",
      description: "Streamline your business processes by automating repetitive tasks and workflows with AI.",
    },
    {
      src: "/chrome.png",
      alt: "saas",
      title: "Chrome Extensions",
      description: "Develop simple to advanced chrome extensions that can connect to your app or standalone.",
    },
    {
      src: "/API2.png",
      alt: "saas",
      title: "API Integrations",
      description: "We'll help you integrate your software or system with almost any 3rd party application.",
    },
    {
      src: "/web3.png",
      alt: "saas",
      title: "Web3 Development",
      description: "Build on the blockchain using our web3 developers for smart contracts, token-gating, and more.",
    },
    {
      src: "/calendar.png",
      alt: "saas",
      title: "Software Maintenance",
      description: "Let us take care of your Software upkeep, from bugs to changes, or even additional features.", 
    },
   
  ];
  export default function ProductTypes() {
    return (

      <div id="services" className=" rounded-xl max-w-5xl xl:max-w-6xl mx-auto relative p-4 md:p-8 lg:p-12 my-10">
      <div className="text-5xl font-bold text-center">
          <h1>Products & Services</h1>
        </div>
        <div className="max-w-lg text-md text-center  mx-auto py-10">
        <p>Our team of experts can help you develop a wide range of software applications and services. From SAAS Development to AI and Automation, we have you covered.</p>
        </div>
<div className="mx-auto max-w-7xl px-6 lg:px-8">
<div className=" grid grid-cols-1 gap-0.5 overflow-hidden  sm:rounded-2xl md:grid-cols-2 lg:grid-cols-3 max-w-md mx-auto md:mx-0 md:max-w-none">
{logos.map((logo, index) => (
  <div key={index} className="bg-gray-400/5 p-10  rounded-xl border border-2 border-black m-2">

    <div className="flex flex-row ">
          <img
      className="h-10 p-2 object-contain"
      src={logo.src}
      alt={logo.alt}
    
    />
    <p className="font-bold text-xl self-center">{logo.title}</p>
    </div>
    <p className="text-sm">{logo.description}</p>
  
  </div>

))}
</div>
</div>
</div>
         )
        }