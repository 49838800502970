import React from 'react';
import { useInView } from 'react-intersection-observer';

const TimelineItem = ({ heading, sub, para, img }) => {
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.5 // Adjust based on when you want to trigger the visibility change
  });

  const opacity = inView ? 1 : 0.1;

  return (
    <div ref={ref} className='h-full border border-black border-2 shadow-xl rounded-xl p-2 mb-16' style={{ opacity, transition: 'opacity 0.5s ease-in-out' }}>
      <div className='sm:flex sm:flex-row '>
        <img src={img} alt='img'  className='sm:h-60'/>
        <div className='self-center'>    
            <p className='text-4xl font-bold'>{heading}</p>
            <p className='text-lg py-2'>{sub}</p>
            <p className='text-md'>{para}</p>
        </div>
    
      </div>
    </div>
  );
};

export default TimelineItem;
