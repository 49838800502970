import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
  {
    question: "Why wouldn't I just hire a full-time developer?",
    answer:
      "Good question! For starters, the annual cost of a full-time senior-level engineer now exceeds $140,000, plus benefits. Aside from that, you may not always have enough work to keep them busy at all times, so you're stuck paying for time you aren't able to utilize. With the monthly plan, you can pause and resume your subscription as often as you need to ensure you're only paying your developer when you have work available for them.",
  },
  {
    question: "Is there a limit to how many requests I can have?",
    answer:
      "Once subscribed, you're able to add as many design requests to your queue as you'd like, and they will be delivered one by one.",
  },
  {
    question: "How fast will I receive my software?",
    answer:
      "On average, most requests are completed in just 2 days or less. However, more complex requests can take longer.",
  },
  {
    question: "Who are the developers?",
    answer:
      "You might be surprised to hear this, but SoftwareNow is actually an agency of one. This means you'll work directly with me, founder of SoftwareNow.",
  },
  {
    question: "How does the pause feature work?",
    answer:
      "We understand you may not have enough development work to fill up entire month. Perhaps you only have one or two software requests at the moment. That's where pausing your subscription comes in handy. Billing cycles are based on 31 day period. Let's say you sign up and use the service for 21 days, and then decide to pause your subscription. This means that the billing cycle will be paused and you'll have 10 days of service remaining to be used anytime in the future.",
  },
  {
    question: "What languages and frameworks do you develop?",
    answer:
      "FrontEnd: React Backend: Node.js + Express / Python + Flask, Databases: NoSQL + SQL, Deployment: AWS",
  },
  {
    question: "How do I request software?",
    answer:
      "SoftwareNow offers a ton of flexibility in how you request software using Trello. Some common ways clients request designs is directly via Trello, sharing Google docs or wireframes, or even recording a brief Loom video (for those who prefer not to write their briefs out). Basically, if it can be linked to or shared in Trello, it's fair game.",
  },
  {
    question: "What if I don't like the software?",
    answer:
      "No worries! We'll continue to revise the code until you're 100% satisfied.",
  },
//   {
//     question: "Is there any development work you don't cover?",
//     answer:
//       "",
//   },
  {
    question: "What if I only have a single request?",
    answer:
      "That's fine. You can pause your subscription when finished and return when you have additional development needs. There's no need to let the remainder of your subscription go to waste.",
  },
  {
    question: "Are there any refunds if I don't like the service?",
    answer:
      "Due to the high quality nature of the work, there will be no refunds issued.",
  },
]

export default function Example() {
  return (
    <div id="faq" className="bg-white  ">
      <div className="mx-auto max-w-7xl px-6   sm:px-0  ">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2 className="text-4xl sm:text-6xl sm:max-w-3xl font-bold leading-10 tracking-tight text-gray-900">Commonly asked questions</h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
