import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import styled, { keyframes } from 'styled-components';

const navigation = [
  { name: 'Product', href: '#' },
  { name: 'Features', href: '#' },
  { name: 'Company', href: '#' },
]


const backgroundFade = keyframes`
  0% {
    background-color: #000000;
  }
  14% {
    background-color: #666666    ;
  }
  28% {
    background-color: #000000;
  }
  42% {
    background-color: #666666    ;
  }
  56% {
    background-color: #000000;
  }
  70% {
    background-color: #666666    ;
  }
  84% {
    background-color: #000000;
  }
  100% {
    background-color: #666666    ;
  }
`;
const backgroundFade2 = keyframes`
  0% {
    background-color: #f79533;
  }
  14% {
    background-color: #141414
    ;
  }
  28% {
    background-color: #282828;
  }
  42% {
    background-color: #3d3d3d;
  }
  56% {
    background-color: #515151;
  }
  70% {
    background-color: #666666;
  }
  84% {
    background-color: #848484;
  }
  100% {
    background-color: #a3a3a3;
  }
`;
const StyledButton = styled.button`
  padding: 8px 10px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: white;
  border-radius: 11px;
  animation: ${backgroundFade} 3s infinite;
  outline: none;



`;
const AnimatedGradientText = styled.span`
  font-size: 24px; // or your desired size
  font-weight: bold; // assuming you want it bold
  color: white; // text color, might need adjustment based on your design
  background-clip: text;
  -webkit-background-clip: text;
  color: black; // Makes the text color transparent so the background shows
  // animation: ${backgroundFade} 3s infinite;
  display: inline-block; // Ensures background-clip works correctly
`;
export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <header className="bg-white">
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex flex-1">
          {/* <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-900">
                {item.name}
              </a>
            ))}
          </div> */}
          {/* <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div> */}
        </div>
        {/* <a href="#" className="-m-1.5 p-1.5">
          <span className="sr-only">Your Company</span>
          <AnimatedGradientText>SoftwareNow</AnimatedGradientText>
        </a> */}
        <div className="flex flex-1 justify-end">
          {/* <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
            Log in <span aria-hidden="true">&rarr;</span>
          </a> */}
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 left-0 z-10 w-full overflow-y-auto bg-white px-6 py-6">
          <div className="flex items-center justify-between">
            <div className="flex flex-1">
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                alt=""
              />
            </a>
            <div className="flex flex-1 justify-end">
              <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
                Log in <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
          <div className="mt-6 space-y-2">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
              >
                {item.name}
              </a>
            ))}
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}
