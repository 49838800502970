export default function FinePrint() {
    return (
        <>
          {/* <div className='text-center text-5xl font-bold py-10'>
            <h1>SoftwareNow</h1>
        </div> */}
        <div className='text-center text-gray-400 text-sm   pb-10 sm:pb-40'>
            
            <p>© 2024 SoftwareNow. All rights reserved.</p>
            <p>Terms of Service | Privacy Policy</p>
        </div>
        </>
    )
}
